import styled from "@emotion/styled";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useScroll } from "../hooks/useScroll";
import { rem } from "../styling/theme";
import FlexBox from "../ui/FlexBox";

const Wrapper = styled(FlexBox)<{ customHeight?: number }>(
  ({ customHeight }) => ({
    position: "relative",
    width: "100%",
    height: customHeight ? "auto" : rem(customHeight),
    minHeight: "100vh",
  })
);

const HookWrapper = styled(FlexBox)<{
  isActive: boolean;
}>(({ isActive }) => ({
  width: "100%",
  height: "100vh",
  top: isActive ? 0 : "auto",
  position: isActive ? "fixed" : "relative",
  zIndex: isActive ? "auto" : "auto",
}));

const ContentWrapper = styled(FlexBox)<{ isActive: boolean }>(
  ({ isActive }) => ({
    position: "relative",
    width: "100%",
    height: "100vh",
    overflow: isActive ? "scroll" : "hidden",
  })
);

type BlockWrapperT = {
  cover: JSX.Element;
  idForHooks?: string;
  isActive?: boolean;
  activePending?: boolean;
  setActivePending?: Dispatch<SetStateAction<boolean>>;
  setActiveSectionHandle?: () => void;
  forceScrollToSection?: boolean;
};

const BlockWrapper: FC<BlockWrapperT> = ({
  cover,
  idForHooks,
  isActive = false,
  activePending = false,
  setActivePending,
  setActiveSectionHandle,
  forceScrollToSection,
  children,
}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const wrapper = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);
  const { currentOffset } = useScroll();

  useEffect(() => {
    if (
      !firstLoad &&
      wrapper.current &&
      setActiveSectionHandle &&
      setActivePending
    ) {
      if (currentOffset === wrapper.current.offsetTop) {
        setActiveSectionHandle();
        setActivePending(false);
      }
    }

    setFirstLoad(false);
  }, [currentOffset, activePending]);

  useEffect(() => {
    if (activePending) {
      scrollToSeciton();
    }
  }, [activePending]);

  useEffect(() => {
    if (forceScrollToSection) {
      scrollToSeciton();
    }
  }, [forceScrollToSection]);

  const scrollToSeciton = () => {
    if (wrapper.current) {
      wrapper.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Wrapper
      ref={wrapper}
      customHeight={wrapper.current && wrapper.current.clientHeight}
      id={idForHooks}
    >
      <HookWrapper isActive={isActive} flexDirection="column">
        <ContentWrapper
          isActive={isActive}
          flexDirection="column"
          justifyContent="stretch"
          ref={content}
        >
          {cover}
          {children && children}
        </ContentWrapper>
      </HookWrapper>
    </Wrapper>
  );
};

export default BlockWrapper;
