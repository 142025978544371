import styled from "@emotion/styled";
import React, { FC } from "react";
import { getMediaFileType } from "../utils/helpers";
import { MediaFileTypeE } from "../utils/types";
import FlexBox from "./FlexBox";

const Wrapper = styled(FlexBox)<{ position: "relative" | "absolute" }>(
  ({ position }) => ({
    position,
    width: "100%",
    height: "100vh",
  })
);

const VideoBackground = styled.video({
  width: "100vw",
  height: "100vh",
  objectFit: "cover",
  position: "absolute",
  top: 0,
  left: 0,
});

const ImageBackground = styled(FlexBox)<{ url: string }>(({ url }) => ({
  minHeight: "100%",
  width: "100%",
  backgroundImage: `url("${url}")`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));

type BackgroundT = {
  url: string;
  onClick?: () => void;
  position?: "absolute" | "relative";
};

const Background: FC<BackgroundT> = ({ url, position = "relative" }) => {
  const mediaType = getMediaFileType(url);

  return (
    <Wrapper position={position}>
      {mediaType === MediaFileTypeE.video ? (
        <VideoBackground
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          preload={"auto"}
          src={url}
        />
      ) : (
        <ImageBackground url={url} />
      )}
    </Wrapper>
  );
};

export default Background;
