import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import React, { FC, useState } from "react";
import { rem, theme } from "../styling/theme";
import FlexBox from "../ui/FlexBox";
import Icon from "../ui/Icon";
import { IconTypeE } from "../ui/Icons";
import MenuItem from "../ui/MenuItem";
import { DataT, NavigationT } from "../utils/types";

const ItemsWrapper = styled(FlexBox)<{ isActive: boolean }>(({ isActive }) => ({
  position: "relative",
  width: isActive ? "100%" : "0%",
  opacity: isActive ? 1 : 0,
  visibility: isActive ? "visible" : "hidden",
  overflow: "hidden",
  transition: "all 0.4s cubic-bezier(0.22, 1, 0.36, 1)",

  [theme.media.maxMd]: {
    flexDirection: "column",
  },
}));

const ToggleWrapper = styled(FlexBox)<{ isActive: boolean }>(
  ({ isActive }) => ({
    position: "absolute",
    padding: rem(20),
    opacity: isActive ? 1 : 0,
    visibility: isActive ? "visible" : "hidden",
    transition: "all 0.4s cubic-bezier(0.22, 1, 0.36, 1)",

    [theme.media.maxMd]: {
      position: "relative",
      top: rem(300),
    },
  })
);

const Wrapper = styled(FlexBox)<{ isActive: boolean }>(({ isActive }) => ({
  position: "fixed",
  bottom: rem(25),
  height: rem(200),
  left: "50%",
  transform: "translateX(-50%)",
  padding: rem(25),
  paddingBottom: 0,
  cursor: "pointer",
  visibility: isActive ? "visible" : "hidden",
  zIndex: 10,
}));

const Gradient = styled(FlexBox)<{ isActive: boolean }>(({ isActive }) => ({
  position: "fixed",
  width: "100%",
  height: rem(350),
  opacity: isActive ? 1 : 0,
  bottom: 0,
  left: 0,
  zIndex: 3,
  visibility: isActive ? "visible" : "hidden",
  transition: "all 1.2s cubic-bezier(0.22, 1, 0.36, 1)",
  transitionDelay: "0.2s",
  backgroundImage: `linear-gradient(0deg,
    rgba(0,0,0, 0.8) 0%,
    rgba(0,0,0, 0.3) 50%,
    rgba(0,0,0, 0.15) 65%,
    rgba(0,0,0, 0.075) 75.5%,
    rgba(0,0,0, 0.037) 82.85%,
    rgba(0,0,0, 0.019) 88%,
    rgba(0,0,0, 0) 100%
  )`,

  [theme.media.maxMd]: {
    height: rem(550),
  },
}));

type MenuT = {
  isHomePage?: boolean;
};

const Menu: FC<MenuT> = ({ isHomePage = true }) => {
  const { strapiNavigation } = useStaticQuery(query);
  const navigationData: DataT<NavigationT> = strapiNavigation;
  const items = navigationData.data.attributes.items;

  const [hover, setHover] = useState(false);

  return (
    <>
      <Gradient isActive={hover} />
      <Wrapper
        isActive={hover}
        flexDirection="column"
        onMouseLeave={() => setHover(false)}
        onMouseEnter={(prevState) => (prevState ? setHover(true) : null)}
      >
        <ToggleWrapper
          onMouseEnter={() => setHover(true)}
          isActive={!hover}
          className={
            !hover
              ? "animate__animated animate__heartBeat animate__infinite animate__slow"
              : ""
          }
        >
          <Icon size={26} icon={IconTypeE.plus} />
        </ToggleWrapper>
        <ItemsWrapper isActive={hover}>
          {items.map((item) => (
            <MenuItem key={item.id} item={item} isHomePage={isHomePage} />
          ))}
        </ItemsWrapper>
      </Wrapper>
    </>
  );
};

export default Menu;

const query = graphql`
  query {
    strapiNavigation {
      data {
        attributes {
          items {
            id
            label
            source
          }
        }
      }
    }
  }
`;
