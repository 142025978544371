import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem } from "../styling/theme";
import FlexBox from "./FlexBox";
import { getIcon, IconTypeE } from "./Icons";

export type IconSizeT = "default";

type IconStyleT = {
  size: number;
};

const Wrapper = styled(FlexBox)<IconStyleT>(({ size }) => ({
  minWidth: rem(size),
  width: rem(size),
  height: rem(size),
  transition: "all 0.2s",
}));

type IconT = {
  icon: IconTypeE;
  size?: number;
  color?: string;
  onClick?: () => void;
};

const Icon: FC<IconT> = ({ size = 20, color, icon, onClick }) => {
  return (
    <Wrapper onClick={onClick} size={size}>
      {getIcon(icon, color)}
    </Wrapper>
  );
};

export default Icon;
