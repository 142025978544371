import styled from "@emotion/styled";
import "animate.css";
import React, { FC, useContext } from "react";
import { rem, theme } from "../styling/theme";
import FlexBox from "../ui/FlexBox";
import Icon from "../ui/Icon";
import { IconTypeE } from "../ui/Icons";
import Spacer from "../ui/Spacer";
import { AppHelperContext } from "../utils/AppHelperProvider";

const ClaimWrapper = styled(FlexBox)({
  position: "fixed",
  left: rem(40),
  top: 0,
  zIndex: 4,
  cursor: "pointer",

  [theme.media.maxMd]: {
    top: rem(2),
    left: rem(30),
  },

  [theme.media.maxSm]: {
    left: rem(20),
  },
});

const ClaimTitle = styled.h4({
  fontSize: rem(22),
  fontWeight: 400,
  color: theme.color.primaryColor,
  cursor: "inherit",

  [theme.media.maxMd]: {
    fontSize: rem(20),
  },
});

const SocialIconsWrapper = styled(FlexBox)({
  position: "fixed",
  right: rem(40),
  top: rem(30),
  zIndex: 4,

  [theme.media.maxMd]: {
    right: rem(30),
  },

  [theme.media.maxSm]: {
    right: rem(20),
  },
});

const IconWrapper = styled(FlexBox)<{ isActive: boolean }>(({ isActive }) => ({
  width: isActive ? rem(20) : 0,
  marginRight: isActive ? rem(10) : 0,
  overflow: "hidden",
  cursor: "pointer",
  transition: "all 0.4s cubic-bezier(0.22, 1, 0.36, 1)",
}));

const Claim: FC = () => {
  const { activeSection, setActiveSection } = useContext(AppHelperContext);

  const onClickHandle = () => {
    if (!activeSection) {
      window.location = "/" as any as Location;
      return;
    }

    setActiveSection(null);
  };

  return (
    <>
      <ClaimWrapper onClick={onClickHandle}>
        <IconWrapper isActive={!!activeSection}>
          <Icon icon={IconTypeE.arrowLeft} />
        </IconWrapper>
        <ClaimTitle>{"humanoid research lab."}</ClaimTitle>
      </ClaimWrapper>
      <SocialIconsWrapper>
        <a
          href={"https://www.instagram.com/humanoid.research.lab/"}
          title={"@humanoid.research.lab"}
          target="_blank"
        >
          <Icon icon={IconTypeE.instagram} size={24} />
        </a>
        <Spacer size={10} />
        <a
          href={"https://vimeo.com/sorrywecan"}
          title={"sorrywecan"}
          target="_blank"
        >
          <Icon icon={IconTypeE.vimeo} size={24} />
        </a>
      </SocialIconsWrapper>
    </>
  );
};

export default Claim;
