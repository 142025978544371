/* eslint-disable max-len */
import styled from "@emotion/styled";
import * as React from "react";
import { rem, theme } from "../styling/theme";

export const ICON_SAFE_AREA = 2;

const Icon = styled.svg({
  width: "100%",
  height: "100%",
  padding: rem(ICON_SAFE_AREA / 2),

  ["path"]: {
    transition: "all 0.4s",
  },
});

export enum IconTypeE {
  arrowLeft = "arrowLeft",
  arrowRight = "arrowRight",
  plus = "plus",
  vimeo = "vimeo",
  instagram = "instagram",
}

type getIconT = (icon: IconTypeE, color?: string) => JSX.Element;
export const getIcon: getIconT = (icon, color = theme.color.primaryColor) => {
  switch (icon) {
    case IconTypeE.arrowLeft:
      return (
        <Icon viewBox="0 0 16 16">
          <path
            fill={color}
            d="M11.3,16c-0.4,0-0.7-0.1-0.9-0.4L3.7,8.9c-0.5-0.5-0.5-1.3,0-1.9l6.7-6.7c0.5-0.5,1.3-0.5,1.9,0c0.5,0.5,0.5,1.3,0,1.9L6.5,8l5.7,5.7c0.5,0.5,0.5,1.3,0,1.9C12,15.9,11.7,16,11.3,16z"
          />
        </Icon>
      );
    case IconTypeE.arrowRight:
      return (
        <Icon viewBox="0 0 16 16">
          <path
            fill={color}
            d="M4.7,0c0.4,0,0.7,0.1,0.9,0.4l6.7,6.7c0.5,0.5,0.5,1.3,0,1.9l-6.7,6.7c-0.5,0.5-1.3,0.5-1.9,0c-0.5-0.5-0.5-1.3,0-1.9L9.5,8L3.8,2.3C3.3,1.8,3.3,1,3.8,0.4C4,0.1,4.3,0,4.7,0z"
          />
        </Icon>
      );
    case IconTypeE.plus:
      return (
        <Icon viewBox="0 0 16 16" enableBackground="new 0 0 16 16">
          <path
            fill={color}
            d="M15.7,8c0,0.4-0.4,0.8-0.8,0.8H8.8V15c0,0.5-0.3,0.8-0.8,0.8S7.2,15.4,7.2,15V8.8H1C0.6,8.8,0.3,8.5,0.3,8S0.6,7.2,1,7.2h6.2V1c0-0.5,0.3-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v6.2H15C15.4,7.2,15.7,7.5,15.7,8z"
          />
        </Icon>
      );
    case IconTypeE.instagram:
      return (
        <Icon viewBox="0 0 16 16" enableBackground="new 0 0 16 16">
          <g>
            <path
              fill={color}
              d="M11.96,0H4.04C1.81,0,0,1.81,0,4.04v7.93C0,14.19,1.81,16,4.04,16h7.93c2.23,0,4.04-1.81,4.04-4.04V4.04C16,1.81,14.19,0,11.96,0z M14.68,11.96c0,1.5-1.22,2.72-2.72,2.72H4.04c-1.5,0-2.72-1.22-2.72-2.72V4.04c0-1.5,1.22-2.72,2.72-2.72h7.93c1.5,0,2.72,1.22,2.72,2.72V11.96z"
            />
            <path
              fill={color}
              d="M7.96,4.04C5.77,4.04,4,5.81,4,8c0,2.18,1.78,3.96,3.96,3.96s3.96-1.78,3.96-3.96C11.91,5.81,10.14,4.04,7.96,4.04z M7.96,10.64C6.5,10.64,5.31,9.45,5.31,8S6.5,5.35,7.96,5.35S10.6,6.54,10.6,8S9.41,10.64,7.96,10.64z"
            />
            <path
              fill={color}
              d="M12.28,2.68c-0.55,0-0.99,0.44-0.99,0.99c0,0.55,0.44,0.99,0.99,0.99c0.54,0,0.99-0.44,0.99-0.99C13.27,3.12,12.83,2.68,12.28,2.68z"
            />
          </g>
        </Icon>
      );
    case IconTypeE.vimeo:
      return (
        <Icon viewBox="0 0 16 16" enableBackground="new 0 0 16 16">
          <path
            fill={color}
            d="M0,4.73l0.76,1.02c0,0,0.43-0.4,1.08-0.72s1.19,0.98,1.28,1.27c0.09,0.29,0.54,1.73,1.28,4.67S6.17,15,7.06,15s2.06-0.27,4.34-2.69s3.52-4.76,4.1-6.16c0.57-1.4,0.5-2.06,0.5-2.36S16.08,1,13.63,1S9.98,2.4,9.19,4.76c0,0,0.61-0.23,1.16-0.23s1.09,0.28,1.09,1.14s-0.75,2.19-1.11,2.71C9.98,8.9,9.37,9.97,8.82,9.97c-0.55,0-1.13-1.86-1.4-3.51C7.15,4.8,6.99,3.25,6.75,2.73c-0.24-0.52-0.6-1.53-1.8-1.53S2.56,2.51,1.87,3.1S0,4.73,0,4.73z"
          />
        </Icon>
      );
  }
};
