import styled from "@emotion/styled";
import React, { FC } from "react";
import Background from "./Background";
import FlexBox from "./FlexBox";

const Wrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  height: "100vh",
});

type CoverT = {
  url: string;
  onClick?: () => void;
};

const Cover: FC<CoverT> = ({ url, onClick, children }) => {
  return (
    <Wrapper onClick={onClick}>
      <Background url={url} />
      {children && children}
    </Wrapper>
  );
};

export default Cover;
