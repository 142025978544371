import styled from "@emotion/styled";
import { rem, theme } from "../styling/theme";

export const TextComponent = styled.div<{
  textColor: string;
  highLightColor: string;
  paragraphSize: number;
}>(({ textColor, highLightColor, paragraphSize }) => ({
  display: "block",
  width: "100%",
  color: textColor,
  whiteSpace: "break-spaces",

  ["a"]: {
    textDecoration: "none",
    color: textColor,
    transition: "all 0.3s cubic-bezier(0.22, 1, 0.36, 1)",

    ["&:hover"]: {
      color: highLightColor,
    },
  },

  ["p"]: {
    fontSize: rem(paragraphSize),
    margin: 0,

    [theme.media.maxMd]: {
      fontSize: rem(paragraphSize < 19 ? paragraphSize : paragraphSize * 0.8),
    },
  },

  ["h1, h2, h3, h4, h5, strong"]: {
    color: highLightColor,
    margin: 0,
  },

  ["h2"]: {
    fontSize: rem(64),

    [theme.media.maxMd]: {
      fontSize: rem(48),
    },

    [theme.media.maxSm]: {
      fontSize: rem(38),
    },
  },

  ["h3"]: {
    fontSize: rem(42),

    [theme.media.maxMd]: {
      fontSize: rem(38),
    },

    [theme.media.maxSm]: {
      fontSize: rem(32),
    },
  },

  ["h4"]: {
    fontSize: rem(36),

    [theme.media.maxMd]: {
      fontSize: rem(28),
    },

    [theme.media.maxSm]: {
      fontSize: rem(24),
    },
  },
}));
