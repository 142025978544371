import styled, { CSSObject } from "@emotion/styled";
import { Link } from "gatsby";
import React, { FC, useContext } from "react";
import * as Scroll from "react-scroll";
import { rem, theme } from "../styling/theme";
import FlexBox from "../ui/FlexBox";
import { AppHelperContext } from "../utils/AppHelperProvider";
import { MenuItemComponentT } from "../utils/types";

const LinkStyle: CSSObject = {
  fontSize: rem(16),
  fontWeight: 400,
  transition: "all 0.7s cubic-bezier(0.22, 1, 0.36, 1)",
  whiteSpace: "pre",
  textTransform: "uppercase",
  color: theme.color.whiteColor,

  ["&:hover"]: {
    color: theme.color.primaryColor,
  },

  [theme.media.maxMd]: {
    fontSize: rem(20),
  },
};

const StyledLink = styled(Link)({
  textDecoration: "none",
  ...LinkStyle,
});

const Wrapper = styled(FlexBox)({
  position: "relative",
  padding: `${rem(10)} ${rem(20)}`,
});

type MenuItemT = {
  item: MenuItemComponentT;
  isHomePage: boolean;
};

const MenuItem: FC<MenuItemT> = ({ item }) => {
  const { label, source } = item;
  const { activeSection, setActiveSection } = useContext(AppHelperContext);

  const isSourceHook = (): boolean => {
    return source[0] === "?";
  };

  const resolveHook = () => {
    const hook = document.getElementById(source.substring(1));

    if (hook) {
      Scroll.animateScroll.scrollTo(hook.offsetTop);

      return;
    }

    window.location = `/${source}` as any as Location;
  };

  const onClickHandle = () => {
    if (activeSection) {
      setActiveSection(null);
    }

    if (isSourceHook()) {
      resolveHook();
    }
  };

  return (
    <Wrapper onClick={onClickHandle}>
      <StyledLink to={source}>{label}</StyledLink>
    </Wrapper>
  );
};

export default MenuItem;
