import styled from "@emotion/styled";
import React, { FC } from "react";
import { useResponsive } from "../hooks/useResponsive";
import { rem, theme } from "../styling/theme";
import FlexBox from "../ui/FlexBox";
import { TextComponent } from "../ui/TextComponent";
import { TextBlockComponentT } from "../utils/types";

export const TextGridWrapperAbsolute = styled(FlexBox)({
  position: "absolute",
  width: "100%",
  maxWidth: rem(1500),
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 3,
});

export const TextGridWrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  maxWidth: rem(1500),
  zIndex: 3,
});

const Wrapper = styled(FlexBox)<{ topOffset: string }>(({ topOffset }) => ({
  position: "relative",
  justifyContent: "stretch",
  alignItems: "flex-start",
  flexWrap: "wrap",
  minWidth: "100%",
  height: "100%",
  marginTop: topOffset,
  transform: topOffset === "40vh" ? "translateY(-50%)" : "none",
}));

const TextItem = styled(FlexBox)<{ customWidth: number; leftOffset: number }>(
  ({ customWidth, leftOffset }) => ({
    width: `calc(${customWidth}% - ${rem(80)})`,
    margin: `${rem(20)} ${rem(40)} ${rem(20)}`,
    marginLeft: leftOffset ? `calc(${leftOffset}% + ${rem(40)})` : rem(40),

    [theme.media.maxMd]: {
      width: `calc(100% - ${rem(60)})`,
      margin: `${rem(30)}`,
    },

    [theme.media.maxSm]: {
      width: `calc(100% - ${rem(40)})`,
      margin: `${rem(20)}`,
    },
  })
);

type TextGridT = {
  items: TextBlockComponentT[];
  linksToNewTab?: boolean;
  centerOnMobile?: boolean;
};

const TextGrid: FC<TextGridT> = ({ items, linksToNewTab, centerOnMobile }) => {
  const { mobileVersion, tabletVersion } = useResponsive();

  return (
    <Wrapper
      topOffset={
        centerOnMobile && (mobileVersion || tabletVersion)
          ? "40vh"
          : items[0] && items[0].y_offset
          ? `${items[0].y_offset}%`
          : "auto"
      }
    >
      {items.length > 0 &&
        items.map((item) => {
          const {
            id,
            text,
            text_color,
            highlight_color,
            width,
            x_offset,
            paragraph_size,
          } = item;

          const getText = (): string => {
            const fixedText = text.replace(
              /text-align:justify;/g,
              "text-align:justify;white-space:pre-line;"
            );

            if (linksToNewTab) {
              return fixedText.replace(/\<a /g, '<a target="_blank" ');
            }

            return fixedText;
          };

          return (
            <TextItem key={id} customWidth={width} leftOffset={x_offset}>
              <TextComponent
                textColor={text_color}
                highLightColor={highlight_color}
                paragraphSize={paragraph_size ? paragraph_size : 18}
                dangerouslySetInnerHTML={{ __html: getText() }}
              />
            </TextItem>
          );
        })}
    </Wrapper>
  );
};

export default TextGrid;
